(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:SearchLicense
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('SearchLicense', SearchLicense);

  function SearchLicense($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/persons/licences');
  }
}());
